<template>
  <router-view></router-view>
</template>

<script>
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
  
</style>

<style type="css">

h1,h2,h3 {
  font-weight:normal;
}
.wolfmeyer .logo{
  background-color:#4f4f4f;

}
 .logo {
  padding:1.1em;
  text-align:center;
}
.logo img {
  margin:auto;
}
ul {
  list-style-type:square
}
.downloads ul {
  list-style-type:none;
}
.downloads ul a {
  font-size:1.3em;
}

.jobs {
  color:##3c4b64;
}

.jobs .card-header {
  font-weight:bold;
}
hr.firstSecond {
  margin:2em 0
}

.video-container {
  position:relative;
  width:100%;
  padding-bottom: 56.25%;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
